<template>
  <div class="update">
    <!-- 顶部导航 -->
    <commonNavBar :title="'更新日志'"></commonNavBar>
    <!-- 加载中 -->
    <van-overlay :show="show">
      <van-loading
        text-color="#0094ff"
        color="#0094ff"
        vertical
      >加载中...</van-loading>
    </van-overlay>
    <!-- 系统内容 -->
    <div
      class="infoBox"
      v-watermark
      ref="infoBox"
      @scroll="onScroll"
    >
      <van-cell-group v-if="versionList.length">
        <van-cell
          :title="item.name"
          center
          is-link
          :label="item.createDatetime | openYmdFormat"
          v-for="(item, index) in versionList"
          :key="index"
          :to="`/update/updateInfo/${item.id}`"
        />
      </van-cell-group>
    </div>
  </div>
</template>

<script>

import { getVerisonList } from '@/api/system'
export default {
  name: 'update',
  data() {
    return {

      show: true,
      versionList: [],
      top: 0
    }
  },
  // 激活页面
  activated() {
    if (this.$refs.infoBox) {
      this.$refs.infoBox.scrollTop = this.top
    }
  },
  // 路由进入页面
  beforeRouteEnter(to, from, next) {
    if (from.path.includes('/update/updateInfo')) {
      next()
    } else {
      next(async (vm) => {
        try {
          vm.show = true
          const { data } = await getVerisonList()
          vm.versionList = [...data.data]
          vm.$refs.infoBox.scrollTop = 0
          vm.show = false
        } catch (error) {
          vm.$toast.fail({
            message: '获取更新列表失败!',
            duration: 500
          })
          vm.show = false
        }
      })
    }
  },
  // 创建页面
  async created() {
    try {
      const { data } = await getVerisonList()
      this.versionList = [...data.data]
      this.show = false
    } catch (error) {
      if (error?.response?.status === 401) {
        return
      }
      this.$toast.fail({
        message: '获取更新列表失败!',
        duration: 500
      })
      this.show = false
    }
  },
  methods: {
    // 列表滚动事件
    onScroll(e) {
      this.top = e.target.scrollTop
    }
  }
}
</script>

<style lang="scss" scoped>
.update {
  ::v-deep {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: auto;

    .van-overlay {
      .van-loading {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .infoBox {
      height: calc(100% - 46px);
      position: fixed;
      width: 100%;
      overflow: auto;
    }
  }
}
</style>
